.navbar {
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10rem;
    background-color: $color-primary;

    &__logo {
      margin-left: 4rem;

      &__link {
        font-size: 2.8rem;
        color: $color-white;
        text-decoration: none;
      }
    }

    &__auth {
      margin-right: 4rem;

      &__link {
        font-size: 1.8rem;
        color: $color-white;
        text-decoration: none;

        &:first-of-type {
          margin-right: 8rem;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 7rem;
    background-color: $color-tertiary;

    &__item {
      list-style: none;

      &__link {
        font-size: 1.8rem;
        color: $color-white;
        text-decoration: none;

        &:hover {
          color: $color-quaternary;
        }
      }
    }
  }
}
