.notfound {
  margin-top: 16rem;

  &__heading {
    font-size: 6.2rem;
    text-align: center;
    opacity: 0.7;
    font-weight: 200;
    margin-bottom: 4rem;
  }

  &__paragraph {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 14rem;
  }
}
