.listingform {
  &__section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__altsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__label {
    font-size: 1.8rem;
    color: $color-primary;
    margin-bottom: 0.8rem;
  }

  &__select {
    font-size: 1.6rem;
    width: 100%;
    height: 3rem;
    outline: none;
    margin-bottom: 1.5rem;
  }

  &__input {
    width: 100%;
    height: 3rem;
    font-size: 1.6rem;
    padding: 0.5rem 0.8rem;
    outline: none;
  }

  &__checkbox {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  &__button {
    padding: 1rem 2rem;
    font-size: 1.8rem;
    color: $color-white;
    margin-top: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.2rem 0.3rem rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:hover {
      background-color: $color-tertiary;
    }

    &--primary {
      background-color: $color-primary;
    }
  }

  &__loader {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
