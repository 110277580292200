// Colors
$color-primary: #328da8;
$color-secondary: #47acad;
$color-tertiary: #2fa18a;
$color-quaternary: #87369e;

$color-grey-light: #efefef;
$color-grey-dark: #333;

$color-hover: #edf5f8;
$color-link-hover: #ccc;

$color-paragraph: #4a4a4a;

$color-green: #34eb49;

$color-white: #fff;
$color-black: #000;

// Font
$default-font-size: 1.6rem;

// Grid
$grid-width: 114rem;
$gutter-vertical: 4rem;
$gutter-vertical-small: 2rem;
$gutter-horizontal: 4rem;
