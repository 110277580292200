*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;

    @include respond(tab-land) {
        font-size: 56.25%;
    }

    @include respond(tab-port) {
        font-size: 50%;
    }

    @include respond(phone) {
        font-size: 80%;
    }
}

body {
    box-sizing: border-box;
    font-family: bebas-neue, sans-serif;

    @include respond(tab-port) {
        padding: 0;
    }
}

::selection {
    background-color: rgba($color-primary, 0.3);
}