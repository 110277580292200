.row {
  max-width: $grid-width;
  margin: 0 auto;

  @include respond(big-desktop) {
    max-width: none;
  }

  &:not(:last-child) {
    margin-bottom: $gutter-vertical;

    @include respond(tab-phone) {
      margin-bottom: $gutter-vertical-small;
    }
  }

  @include respond(tab-port) {
    padding: 0 4rem;
  }

  @include respond(phone) {
    max-width: 45rem;
    padding: 0 2rem;
  }

  @include clearfix;

  [class^="col-"] {
    float: left;

    &:not(:last-child) {
      margin-right: $gutter-horizontal;

      @include respond(tab-port) {
        margin-right: 0;
        margin-bottom: $gutter-vertical-small;
      }
    }

    @include respond(tab-port) {
      width: 100% !important;
    }
  }

  .col-1-of-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  .col-1-of-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
  }

  .col-2-of-3 {
    width: calc(
      2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal}
    );
  }

  .col-1-of-4 {
    width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
  }

  .col-2-of-4 {
    width: calc(
      2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal}
    );
  }

  .col-3-of-4 {
    width: calc(
      3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + 2 * #{$gutter-horizontal}
    );
  }

  .col-1-of-5 {
    width: calc((100% - 4 * #{$gutter-horizontal}) / 5);
  }

  .col-2-of-5 {
    width: calc(
      2 * ((100% - 4 * #{$gutter-horizontal}) / 5) + #{$gutter-horizontal}
    );
  }

  .col-2-of-5 {
    width: calc(
      4 * ((100% - 4 * #{$gutter-horizontal}) / 5) + 3 * #{$gutter-horizontal}
    );
  }

  .col-1-of-12 {
    width: calc((100% - 11 * #{$gutter-horizontal}) / 12);
  }

  .col-1-of-6 {
    width: calc((100% - 5 * #{$gutter-horizontal}) / 6);
  }

  .col-5-of-6 {
    width: calc(
      5 * ((100% - 5 * #{$gutter-horizontal}) / 6) + 4 * #{$gutter-horizontal}
    );
  }
}
